import axios from 'axios'

export const makeContestationStringAllConv = (conv, inputInEnglish, input) => {
  let convStr = ''
  let messages = []
  let messagesInHebrew = []
  conv.map((message, index) => {
    if (index > 0 && !message.ignore) {
      if (message.role === 'assistant') {
        messagesInHebrew.push({
          role: 'assistant',
          content: message.isExistsInYes ? message.answer + '\n Available at Yes' : message.answer,
        })
        messages.push({
          role: 'assistant',
          content: message.isExistsInYes ? message.answer + '\n Available at Yes' : message.answer,
        })
        convStr +=
          'AI: ' +
          (message.isExistsInYes ? message.answer + '\n Available at Yes' : message.answer) +
          '\n'
      } else {
        convStr += 'User: ' + message.inputInEnglish + '\n'
        messagesInHebrew.push({ role: 'user', content: message.inputInEnglish })
        messages.push({ role: 'user', content: message.inputInEnglish })
      }
    }

    if (index === 0) {
      messagesInHebrew.push({
        role: 'assistant',
        content: message.answer,
      })
    }
  })
  convStr += 'User: ' + input + '\n' + 'AI:'
  messages.push({ role: 'user', content: inputInEnglish })
  messagesInHebrew.push({ role: 'user', content: inputInEnglish })
  return { forTurbo: messages, forTurboHebrew: messagesInHebrew, forVinci: convStr }
}

export const makeId = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const htmlDecode = (input) => {
  if (!input) return ''
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const translateText = async (text) => {
  const { data } = await axios.put('/api/translate', {
    text: text,
  })
  return data.translatedText
}
