import { getProperties } from './actions/propertiesActions.js';

export const setCSSVariables = async () => {
  const { colors } = await getProperties();

  const root = document.documentElement;
  Object.keys(colors.main_colors).forEach((key) => {
    root.style.setProperty(key, colors.main_colors[key]);
  });
};
