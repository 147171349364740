import './App.css'
import './style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import { MainPage } from './MainPage'
import { setCSSVariables } from './colors.js'

const App = () => {
  useEffect(() => {
    const setProperties = async () => {
      await setCSSVariables()
    }
    setProperties()
  }, [])

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/:botId" element={<MainPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
