import axios from 'axios'

export const createLog = async (data) => {
  const { question, description, answer, isLike, messageIndex, comment, sessionId, botId } = data

  await axios.post(`/api/logs/${botId}`, {
    question: question,
    description: description,
    answer: answer,
    isLike: isLike,
    messageIndex: messageIndex,
    comment: comment,
    sessionId: sessionId,
    webPageLink: [{ url: '', title: '', clicks: 0 }],
    sourceText: { text: '', sources: '', clicks: 0 },
    sourceLink: [{ url: '', title: '', clicks: 0 }],
  })
}

export const updateLog = async (data) => {
  const { description, comment, messageIndex, sessionId, isLike, botId } = data

  await axios.post(`/api/logs/update/${botId}`, {
    sessionId: sessionId,
    messageIndex: messageIndex,
    description: description,
    isLike: isLike,
    comment: comment,
  })
}

export const updateLogLinkClick = async (data) => {
  const { messageIndex, sessionId, botId, newClick } = data

  try {
    await axios.post(`/api/logs/update/${botId}/links`, {
      sessionId: sessionId,
      messageIndex: messageIndex,
      newClick: newClick,
    })
  } catch (error) {
    console.log(error)
    console.log('Error updating log link click')
  }
}
