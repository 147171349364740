import { InputField } from '../cmps/InputField'
import React, { useState } from 'react'
import { ReactComponent as QuestionIcon } from '../assets/similar-question-icon.svg'
import { ReactComponent as LinkToSource } from '../assets/linkToSource.svg'
import { ReactComponent as SourceIcon } from '../assets/source.svg'
import { ReactComponent as MainLogo } from '../assets/main-logo.svg'
import './chatPage.scss'
import Lottie from 'react-lottie-player'
import Typing from '../assets/typing.json'
import { useEffect } from 'react'
import SourcePopUp from '../cmps/source-pop-up/SourcePopUp'
import { TagsDropdown } from '../cmps/tags/tagsDropdown'
import { allTagsAtom } from '../atoms'
import { useAtom } from 'jotai'
import BottomContainer from '../cmps/bottom-container/BottomContainer'
import { getBotSettings } from '../actions/botSettings'
import { v4 as uuidv4 } from 'uuid'
import TagList from '../cmps/tags/newTags'
import { updateLogLinkClick } from '../actions/botLogs'

export const ChatPage = ({
  allConversation,
  getAns,
  input,
  botId,
  isLimit,
  botData,
  setInput,
  isTyping,
  // setIsTyping,
  placeHolder,
  scrollEl,
  setAllConversation,
  isEnglishUi,
  exampleQuestions,
  handleQuestionPress,
  isShowSourceLink,
  isShowSourceText,
  isInputDisable,
  additionalLinksTitle,
  sessionId,
  messageIndex,
}) => {
  const [allTags] = useAtom(allTagsAtom)
  const [sourcePopUpData, setSourcePopUpData] = useState(false)
  const [botSettings, setBotSettings] = useState([])

  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    const fetchSettings = async () => {
      setBotSettings(await getBotSettings(botId))
    }
    fetchSettings()
  }, [document.body.scrollHeight])

  const handleLikeMessage = async (index, id) => {
    let newArr = [...allConversation]
    let isLike = newArr[index].isLike === 'like' ? false : 'like'
    newArr[index].isLike = isLike
    let lastUser = allConversation.filter((item) => item.role === 'ai')

    let numToUpdate
    lastUser.forEach((item, index) => {
      if (item.id === id) {
        numToUpdate = index
      }
    })
    setAllConversation(newArr)
  }

  const settings = [
    {
      name: 'description',
      options: [
        {
          label: 'Critial Incorrect Answer',
          value: 'critial-incorrect',
          id: 1,
        },
        {
          label: 'Non-Critial Incorrect Answer',
          value: 'non-critial-incorrect',
          id: 2,
        },
        {
          label: 'Critial Partial Answer',
          value: 'critical-partial-incorrect',
          id: 3,
        },
        {
          label: 'Non Critial Partial Answer',
          value: 'non-critical-partial-incorrect',
          id: 4,
        },
      ],
    },
  ]

  const handleDislikeMessage = async (index, id) => {
    let newArr = [...allConversation]
    let isLike = newArr[index].isLike === 'dislike' ? false : 'dislike'
    newArr[index].isLike = isLike
    let lastUser = allConversation.filter((item) => item.role === 'ai')
    let numToUpdate
    lastUser.forEach((item, index) => {
      if (item.id === id) {
        numToUpdate = index
      }
    })
    setAllConversation(newArr)
  }
  const handleSourcePress = (uniqueSources) => {
    setSourcePopUpData(uniqueSources)

    updateLogLinkClick({
      messageIndex,
      sessionId,
      botId,
      newClick: { name: 'sourceText', sources: [...uniqueSources] },
    })
  }

  const extractSourcesForPopUp = (arr) => {
    const uniquePages = []
    const uniqueSources = []
    if (arr.length === 0) return []

    arr.forEach((obj, index) => {
      // Find entry with the same URL and page (allowing page to be null or a number)
      const found = uniquePages.find((i) => i.url === obj.url && i.page === obj.page)

      uniqueSources.push({
        url: obj.url,
        title: obj.title || obj. metadata.file_title,
        content: obj.content,
        name: isEnglishUi ? `Cite ${index + 1}` : `ציטוט ${index + 1}`,
      })
      if (!found) {
        uniquePages.push({
          url: obj.url,
          content: obj.content,
          title: obj.title,
          page: obj.page,
        })
      }
    })

    const linkElements = (
      <div className="source-box" key={uuidv4}>
        <div className="sources" key={uuidv4}>
          {isShowSourceLink && (
            <div className="source" key={uuidv4}>
              <>
                <LinkToSource className="riu-refrence-icon" target="_blank" style={{ cursor: 'auto' }} />
                <span style={{ pointerEvents: 'none' }}>
                  {isEnglishUi ? 'Source pages: ' : ` עמודי מקור:`}
                </span>
              </>
              {uniquePages.map((item, index) => (
                <div key={index}>
                  <a
                    onClick={() =>
                      updateLogLinkClick({
                        messageIndex,
                        sessionId,
                        botId,
                        newClick: { name: 'sourceLink', url: item.url, title: item.title },
                      })
                    }
                    title={isEnglishUi ? 'Source pages' : `עמודי מקור:`}
                    href={item.page ? item.url + '#page=' + item.page : item.url}
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                  >
                    {item.title}
                    {item.page ? (isEnglishUi ? `, page ${item.page}` : `, עמוד ${item.page}`) : ''}
                    {index !== uniquePages.length - 1 && ','}
                  </a>
                </div>
              ))}
            </div>
          )}
          {isShowSourceText && (
            <div className="source" key={3}>
              <SourceIcon style={{ cursor: 'auto' }} />
              <p onClick={() => handleSourcePress(uniqueSources)}>
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  {isEnglishUi ? 'Sources cites' : 'ציטוטי מקורות'}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    )
    return linkElements
  }

  function convertTextToBold(inputString) {
    // Remove all occurrences of "###"
    const cleanedString = inputString.replace(/###/g, '')
    // Regex to find text between double asterisks
    const regex = /\*\*(.*?)\*\*/g
    // Split the cleaned string by the regex
    const parts = cleanedString.split(regex)
    // Map over the parts and wrap the matched parts with <b> tags
    return parts.map((part, index) => (index % 2 === 1 ? <b key={index}>{part}</b> : part))
  }

  const showLinks = (messageLinks) => {
    if (!messageLinks) return false
    const val = messageLinks && messageLinks.filter((link) => link.link).length > 0
    const isShowLinks = botData.additionalLinks.isSelected
    return val && isShowLinks
  }
  const showLinksTitle = (messageLinksTitle) => {
    if (!messageLinksTitle) return false
    const isShowLinks = botData.additionalLinks.isTitleSelected
    return isShowLinks
  }
  const filterLinks = (messageLinks) => messageLinks && messageLinks.filter((link) => link.link)

  return (
    <div
      ref={scrollEl}
      className="chat-page"
      style={isEnglishUi ? { paddingLeft: '40px', direction: 'ltr' } : { paddingRight: '40px' }}
    >
      {allTags && typeof allTags === 'object' && Object.keys(allTags).length > 0 && <TagList />}
      {sourcePopUpData && (
        <SourcePopUp sourcePopUpData={sourcePopUpData} setSourcePopUpData={setSourcePopUpData} />
      )}
      {allConversation.map((message, index) => (
        <div key={message.id}>
          {message.role === 'assistant' && message.content ? (
            <div
              className="answer-container"
              style={isEnglishUi ? { borderTopLeftRadius: 0 } : { borderTopRightRadius: 0 }}
            >
              <div className={isEnglishUi ? 'left-triangle' : 'triangle'}></div>
              <div className="svg-container" style={{ marginTop: '6px' }}>
                <MainLogo />
              </div>
              <div className="line"></div>

              <div className="ans-container">
                <p style={{ direction: isEnglishUi ? 'ltr' : 'rtl' }}>
                  {' '}
                  {message.content.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {convertTextToBold(line)}
                      {index !== message.content.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>

                <>
                  <div className="source-box">
                    <div className="file">
                      <div className="sources">
                        {!message.sources ? '' : extractSourcesForPopUp(message.sources)}
                      </div>
                    </div>
                  </div>
                  {showLinks(message.additionalLinks) && (
                    <div className="link-box">
                      <ul>
                        {showLinksTitle(additionalLinksTitle?.content) && (
                          <div className="link-title" dir={isEnglishUi ? 'ltr' : 'rtl'}>
                            {additionalLinksTitle.content}
                          </div>
                        )}
                        {filterLinks(message.additionalLinks).map((link) => (
                          <li key={link.link} dir={isEnglishUi ? 'ltr' : 'rtl'}>
                            <div className="links">
                              <LinkToSource className="icon" />
                              <a
                                onClick={() =>
                                  updateLogLinkClick({
                                    messageIndex,
                                    sessionId,
                                    botId,
                                    newClick: { name: 'webPageLink', url: link.link, title: link.title },
                                  })
                                }
                                href={link.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link.title}
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {index > 0 && botSettings.validations_selected && (
                    <BottomContainer
                      key={uuidv4}
                      settings={settings}
                      botSettings={botSettings}
                      validations={JSON.parse(botSettings.validations_selected)}
                      message={message}
                      index={index}
                      handleLikeMessage={handleLikeMessage}
                      handleDislikeMessage={handleDislikeMessage}
                      isTyping={isTyping}
                      // setIsTyping={setIsTyping}
                      botId={botId}
                    />
                  )}
                </>
              </div>
            </div>
          ) : (
            <div
              className="question-container"
              style={isEnglishUi ? { margin: '25px 0px 25px 30px' } : { margin: '25px 60px 25px 0' }}
            >
              <div className="user-input">{message.content}</div>
            </div>
          )}
        </div>
      ))}
      {isTyping ? (
        <div className="answer-container">
          <div className="svg-container" style={{ marginTop: '6px' }}>
            <MainLogo />
          </div>
          <Lottie alt="מקליד" title="מקליד" loop animationData={Typing} play className="yes-typing" />
        </div>
      ) : (
        <>
          {exampleQuestions.length !== 0 && !isLimit && (
            <div className="flex-example-questions">
              <div className="icon-and-label">
                <QuestionIcon />
                <span className="questions-label">
                  {isEnglishUi ? 'Similar questions:' : 'שאלות דומות :'}
                </span>
              </div>
              <div className="flex-questions">
                {exampleQuestions.map((item) => {
                  return (
                    <button
                      onClick={() => handleQuestionPress(item.id, item.content, item)}
                      className="example-question-btn"
                      key={item.id}
                      style={{
                        dir: isEnglishUi ? 'ltr' : 'rtl',
                        direction: isEnglishUi ? 'ltr' : 'rtl',
                      }}
                    >
                      {item.content}
                    </button>
                  )
                })}
              </div>
            </div>
          )}
        </>
      )}
      <InputField
        placeHolder={placeHolder}
        getAns={getAns}
        input={input}
        setInput={setInput}
        isEnglishUi={isEnglishUi}
        isInputDisable={isInputDisable}
      />
    </div>
  )
}
