import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import CloseIcon from '@mui/icons-material/Close'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ListItemText, Chip, Stack, IconButton } from '@mui/material'
import { useAtom } from 'jotai'
import { allTagsAtom, selectedTagsAtom } from '../../atoms'
import './tags-dropdown.scoped.css'

export const TagsDropdown = () => {
  const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom)
  const [allTags] = useAtom(allTagsAtom)

  // Function to handle the removal of a chip
  const handleDelete = (chipToDelete) => () => {
    setSelectedTags((tags) => tags.filter((tag) => tag !== chipToDelete))
  }

  return (
    <div className="flex-autocomplete-chips">
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        // options={allTags}
        options={['elior']}
        value={selectedTags}
        onChange={(event, newValue) => {
          setSelectedTags(newValue)
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.tag_ui}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8, color: 'var(--main-clr)' }}
              checked={selected}
            />
            <ListItemText style={{ fontFamily: 'Assistant' }} primary={option.tag_ui} />
          </li>
        )}
        style={{ width: 200 }}
        renderTags={() => null} // Disable internal chip rendering
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Tags" // Only placeholder, no label
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true, // Remove underline
              style: {
                borderRadius: '5px',
                fontFamily: 'Assistant',
                backgroundColor: 'white',
                paddingTop: '5px',
              }, // Adjust margin to reduce space
            }}
            InputLabelProps={{
              shrink: false, // Prevents the label area from taking up space even when hidden
            }}
          />
        )}
      />
      {/* Chips container */}
      <Stack direction="row" spacing={1}>
        {selectedTags.map((tag) => (
          <Chip
            sx={{
              padding: '0px 20px',
              color: 'var(--text-clr)',
              backgroundColor: 'var(--main-undertone-clr)',
              fontFamily: 'Assistant',
            }}
            key={tag}
            label={tag.tag_ui}
            onDelete={handleDelete(tag)}
            color="primary"
            deleteIcon={
              <IconButton size="small">
                <CloseIcon
                  style={{
                    fontFamily: 'Assistant',
                    height: '20px',
                    width: '20px',
                    color: 'var(--text-clr)',
                    border: '1px solid var(--text-clr)',
                    borderRadius: '50%',
                  }}
                />
              </IconButton>
            }
          />
        ))}
      </Stack>
    </div>
  )
}
