import './inputField.scss'
import { ReactComponent as GetAnsBtn } from '../assets/get-ans-btn.svg'
import { useEffect, useRef } from 'react'

export const InputField = ({
  getAns,
  input,
  setInput,
  placeHolder,
  inputDisabled,
  isEnglishUi,
  isInputDisable,
}) => {
  const inputRef = useRef(null)

  const handleChange = ({ target }) => {
    setInput(target.value || '')
  }
  const handleKeyDown = async ({ key }) => {
    if (key === 'Enter') {
      await getAns()
    }
  }

  useEffect(() => {
    const x = window.matchMedia('(min-width: 800px)').matches
    if (x) inputRef.current.focus()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div
      style={{ opacity: inputDisabled ? 0.85 : 1 }}
      className={isEnglishUi ? 'input-field-container english' : 'input-field-container'}
    >
      <input
        disabled={isInputDisable}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        className="input-field"
        placeholder={placeHolder}
        value={input}
        onChange={handleChange}
        type="text"
        // maxLength={250}
        alt="שדה להקלדת השאלה \ חיפוש"
        title="שדה להקלדת השאלה \ חיפוש"
        style={{ padding: isEnglishUi ? '0 60px 0 32px' : '0 32px 0 60px' }}
      />
      <GetAnsBtn
        onKeyUp={(ev) => {
          if (ev.keyCode === 13) {
            getAns()
          }
        }}
        tabIndex="0"
        style={
          isEnglishUi
            ? { right: '18px', top: '13px', transform: 'rotate(180deg)' }
            : { left: '18px', top: '13px' }
        }
        alt="שלח שאלה"
        title="שלח שאלה"
        onClick={() => getAns()}
        className="get-ans-btn"
      />
    </div>
  )
}
