import './bottomContainer.scss';
import React, { useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select, MenuItem } from '@mui/material';

import { ReactComponent as LikeIconActive } from '../../assets/like-icon-active-lm.svg';
import { ReactComponent as LikeIcon } from '../../assets/like-icon-inactive-lm.svg';
import { ReactComponent as DisLikeIconActive } from '../../assets/dislike-icon-active-lm.svg';
import { ReactComponent as DisLikeIcon } from '../../assets/dislike-icon-inactive-lm.svg';
// import { ReactComponent as ChatboxIcon } from '../../assets/chatbox-icon-lm.svg';
// import { ReactComponent as ChatboxClose } from '../../assets/chatbox-close-icon-lm.svg';

import { useAtom } from 'jotai';
import { isPreviewAtom } from '../../atoms/preview';
import { questionAtom } from '../../atoms/chatAtoms';
import { updateLog } from '../../actions/botLogs';
import { useSnackbar } from 'notistack';
import Snackbar from '../Snackbar/Snackbar';

const BottomContainer = ({
  settings,
  validations,
  botSettings,
  message,
  index,
  handleLikeMessage,
  handleDislikeMessage,
  // isTyping,
  // setIsTyping,
  botId,
}) => {
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  // const [isLikeDislike, setIsLikeDislike] = useState(false);
  const [question] = useAtom(questionAtom);
  const [isPreview] = useAtom(isPreviewAtom);
  const { enqueueSnackbar } = useSnackbar();
  const isHebrew = botSettings.isenglishui === 'Hebrew';

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleMessage = (event) => {
    const value = event.target.value;
    setComment(value);
    setIsTyping(value.trim() !== '');
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleMouseDown = () => {
    setIsTyping(true);
  };

  const handleMouseEnter = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    if (!comment.trim()) {
      setIsTyping(false);
    }
  };

  const enterPressFunction = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleMessage(e);
      setIsTyping(false);
      e.target.blur();
    }
  };

  const handleSend = async (type) => {
    enqueueSnackbar('Thank you for your feedback!', {
      content: (key, message) => <Snackbar type={'success'} message={message} />,
    });
    try {
      const data = {
        question: question.question,
        description,
        answer: message.content,
        isLike: message.isLike,
        messageIndex: question.index,
        comment,
        sessionId: question.sessionId,
        botId,
      };
      await updateLog(data);
    } catch (error) {
      console.log(error);
    } finally {
      if (type === 'send') {
        // setIsLikeDislike(true)
        setIsSent(true);
      }
    }
  };

  return (
    <div className="bottom-container">
      {validations.includes('like+dislike') && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            marginBottom: '10px',
            gap: '10px',
          }}
        >
          <div
            alt="לא אהבתי את ההמלצה "
            title="לא אהבתי את ההמלצה"
            tabIndex="0"
            onClick={() => {
              if (!isSent) {
                handleDislikeMessage(index, message.id);
                handleSend();
                // setIsLikeDislike(true)
              }
            }}
            className="like-container"
          >
            {message.isLike === 'dislike' ? <DisLikeIconActive /> : <DisLikeIcon />}
          </div>
          <div
            alt=" אהבתי את ההמלצה"
            title=" אהבתי את ההמלצה"
            tabIndex="0"
            onClick={() => {
              if (!isSent) {
                handleLikeMessage(index, message.id);
                handleSend();
                // setIsLikeDislike(true)
              }
            }}
            className="like-container"
          >
            {message.isLike === 'like' ? <LikeIconActive /> : <LikeIcon />}
          </div>
        </div>
      )}
      {validations.includes('dropdown-menu') && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
            gap: '10px',
          }}
        >
          <div>
            <Select
              className="select-box-tool-box"
              value={description}
              disabled={isSent}
              displayEmpty
              onChange={(e) => {
                if (!isSent) {
                  handleDescription(e);
                  handleSend();
                }
              }}
            >
              {settings[0].options.map((option) => (
                <MenuItem key={uuidv4()} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}
      {validations.includes('comment') && (
        <div>
          {message.isLike !== null && (
            <label
              disabled={isPreview}
              htmlFor="comment-textarea"
              className={`flex-comment ${
                isTyping || (message.comment && message.comment.trim() === '')
                  ? 'active-bg'
                  : 'unactive-bg'
              }`}
              onKeyPress={(e) => enterPressFunction(e)}
              style={{
                direction: isHebrew ? 'rtl' : 'ltr',
              }}
            >
              {/* <button disabled={isPreview}>
                <ChatboxIcon disabled={isPreview} />
              </button> */}
              <textarea
                // className="comment-textarea"
                readOnly={isSent}
                rows={1}
                placeholder={isHebrew ? 'הוסף תגובה...' : 'Add a comment...'}
                // onFocus={handleFocus}
                // onMouseDown={handleMouseDown}
                // onBlur={handleBlur}
                // onMouseEnter={handleMouseEnter}
                value={comment}
                onChange={handleMessage}
                style={{
                  // background:
                  //   isTyping || (message.comment && message.comment.trim() === '') ? 'transparent' : '',
                  marginTop: '10px',
                }}
                // onKeyPress={(e) => enterPressFunction(e)}
              />
              {/* {isTyping && (
                <button className="close-chatbox-btn">
                  <ChatboxClose />{' '}
                </button>
              )} */}
              <div className="submit-btn-strip">
                <button
                  style={{
                    cursor: 'pointer',
                    color: 'var(--text-clr)',
                    backgroundColor: 'var(--main-clr)',
                    marginTop: '10px',
                  }}
                  disabled={isSent}
                  className={`submit-btn`}
                  type="submit"
                  onClick={() => handleSend('send')}
                >
                  Send
                </button>
              </div>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default BottomContainer;
