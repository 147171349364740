import './header.scss';
import './toggle-btn/toggle-btn.scss';
import { ReactComponent as MainLogo } from '../assets/main-logo.svg';
import { ReactComponent as NewConversationBtn } from '../assets/new-conversation-btn.svg';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { allTagsAtom } from '../atoms';
import { useAtom, useSAtom } from 'jotai';
import { TagsDropdown } from './tags/tagsDropdown';

export const Header = ({ isEnglishUi }) => {
  const [allTags] = useAtom(allTagsAtom);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <div className="header" style={{ flexDirection: isEnglishUi ? 'row-reverse' : 'row' }}>
      <MainLogo style={{ cursor: 'auto' }} />
      <div className="left-side">
        <div className="new-conversation-container" onClick={refreshPage}>
          {isEnglishUi ? <p>New Chat</p> : <p>שיחה חדשה</p>}
          <NewConversationBtn className="new-conversation-btn" />
        </div>
      </div>
    </div>
  );
};
